<!-- foto guidelines modal -->
<app-modal *ngIf="fotoGuidelinesOpen" (close)="fotoGuidelinesOpen = false" class="foto-guide-modal">
  <h4>Foto Guidelines</h4>
  <div>
    <p>
      Bitte lade zwei Fotos in einem <b>neutralen Outfit</b> und vor einem
      <b>schlichten Hintergrund</b> hoch: ein Ganzkörperfoto und ein
      Portraitbild sind optimal. Achte darauf, dass du hierbei weder einen Hut
      oder eine Sonnenbrille oder auffällige Accessoires trägst. Bedenke, dass
      du dich mit diesen Bildern bewirbst und ein erster Eindruck gegenüber
      Kunden entsteht.
    </p>
    <p><b>neutraler Hintergrund</b></p>
    <p><b>Gesicht zur Kamera</b></p>
    <p><b>einfarbiges Oberteil</b></p>
    <p><b>Optional Sakko/Blazer</b></p>
    <p><b>Einfarbige Hose (ohne Löcher!)</b></p>
    <p><b>Gepflegte Schuhe</b></p>
    <p><b>Tattoos verdecken</b></p>
    <p><b>Piercings ablegen</b></p>
    <p><b>max. 5 Fotos</b></p>
  </div>
  <div class="flex space-between gap-7">
    <img src="assets/img/foto-guide-1.png" />
    <img src="assets/img/foto-guide-2.png" />
    <img src="assets/img/foto-guide-3.png" />
  </div>

  <app-button color="secondary-gradient" (click)="fotoGuidelinesOpen = false">OK</app-button>
</app-modal>

<!-- Modal for experiences -->
<app-modal *ngIf="infoModals.experiences" (close)="infoModals.experiences = false">
  <h1>Eintragen von Erfahrungen</h1>
  <p>
    Bitte teile deine Erfahrung mit uns getrennt nach <b>Veranstaltung</b> (auf
    welcher Veranstaltung hast du gearbeitet?), <b>Tätigkeit</b> (auf welcher
    Position hast du gearbeitet? Bsp.: Service, Einlass, Infocounter etc.) und
    <b>Markenname</b> (für welche Endkunden hast du gearbeitet?). Achtung!
    Topteam ist in der Regel nicht die Marke.
  </p>
  <p>
    Jedes Event kann so als einzelner Block erstellt werden. Wir empfehlen (wenn
    möglich) 3-5 Blöcke der letzten/aussagekräftigsten Veranstaltungen zu
    erstellen. Sind es mehr, nutze gerne das Feld „Sonstige Erfahrungen“, um zu
    beschreiben, dass es noch diverse Einsätze mehr sind.
  </p>
</app-modal>

<!-- Modal for insurance information -->
<app-modal *ngIf="infoModals.insurance" (close)="infoModals.insurance = false">
  <h1>Sozialversicherungsnummer</h1>
  <p>
    Eine deutsche Sozialversicherungsnummer besteht immer aus folgender
    Kombination: Zwei Ziffern für den Rentenversicherungsträger, 6 Ziffern für
    dein Geburtsdatum (TTMMJJ), Anfangsbuchstabe deines Geburtsnamens,
    dreistellige Prüfziffer. Bitte überprüfe die korrekte Eingabe. Solltest du
    deine SV-Nummer nicht zur Hand haben, kannst du diese einfach bei deiner
    Krankenkasse erfragen oder direkt beantragen.
  </p>
  <p>
    Solltest du über eine ausländische Sozialversicherungsnummer verfügen, trage
    diese bitte trotzdem in das Feld ein.
  </p>
</app-modal>

<!-- Modal for status information -->
<app-modal *ngIf="infoModals.status" (close)="infoModals.status = false">
  <h1>Status</h1>
  <p>
    Bitte wähle deinen Status aus. Mehrfachnennungen sind möglich
    (beispielsweise eine Kombination aus Studium und Job als Werkstudierende(r)
    oder Minijob). Jeder ausgewählte Status erfordert einen hochgeladenen
    Nachweis (Bsp.: Immatrikulationsbescheinigung für Studierende, letzte
    Lohnabrechnung für Arbeitnehmer egal welcher Anstellungsart).
  </p>
</app-modal>

<!-- Modal for tax id information -->
<app-modal *ngIf="infoModals.taxId" (close)="infoModals.taxId = false">
  <h1>Steueridentifikationsnummer (SteuerID)</h1>
  <p>
    Du findest deine SteuerID in deinem Einkommenssteuerbescheid, in deiner
    Lohnsteuerbescheinigung, in jeglichen Schreiben vom Bundeszentralamt für
    Steuern oder in Dokumenten vom Finanzamt.
  </p>
</app-modal>

<!-- Modal for tax class information -->
<app-modal *ngIf="infoModals.taxClass" (close)="infoModals.taxClass = false">
  <h1>Steuerklasse</h1>
  <p>
    Wir möchten wissen, über welche Steuerklasse du mit deiner Beschäftigung für
    die Topteam GmbH abgerechnet wirst. Wenn du keinen Hauptarbeitgeber hast,
    ist es in der Regel die Steuerklasse 1. Ist ein Hauptarbeitgeber vorhanden
    (darunter fällt kein Minijob), ist es die 6.
  </p>
</app-modal>

<!-- laufendes jahr approve modal -->
<app-modal *ngIf="approve_laufendes_jahr" (close)="approve_laufendes_jahr = false">
  <div class="approve-laufendes-jahr-modal">
    <h4 class="">Kurzfristige Beschäftigung prüfen</h4>
    <div class="">
      <p class="subscription">
        Da gesetzlich max. 70 Tage pro Jahr erlaubt sind, bitte die gemachten
        Angaben nochmals prüfen und bestätigen.
      </p>
      <div *ngFor="let ljt of laufendesJahrTage" [ngClass]="{ alert: ljt.tage_beschaeftigt > 70 }">
        <b>Gesamt {{ ljt.year }}: {{ ljt.tage_beschaeftigt }}</b>/70 Tage
      </div>
    </div>
    <div>
      <div class="tags modal-tags" [formGroup]="laufendesJahrCheckBoxes">
        <span *ngFor="let item of laufendes_jahr_array">
          <span>
            <input type="checkbox" [name]="item.formControlName" [id]="item.formControlName + '_lj'"
              [formControlName]="item.formControlName" />
            <label [for]="item.formControlName + '_lj'">{{ item.label }}</label>
          </span>
        </span>
      </div>
      <div *ngIf="laufendesJahrFG.invalid && submitAttempt" class="alert">
        Bitte gebe deine Beschäftigungen
        {{ projectYears.length > 1 ? "in den Jahren" : "im Jahr" }}
        {{ projectYears.join(", ") }} an.
      </div>

      <span [formGroup]="laufendesJahrFG">
        <div *ngFor="let key of laufendes_jahr_enabled_FG_keys" class="additional-container">
          <div class="additional">
            <div>
              <h4>{{ laufendes_jahr[key].label }}</h4>
              <p class="subscription">
                Alle Beschäftigungen
                <span *ngIf="!this.isPersEditFormOnly">{{ projectYears.length > 1 ? "in den Jahren" : "im Jahr" }}
                  {{ projectYears.join(", ") }}</span>
                eintragen.
              </p>
            </div>
            <div class="berufserfahrung" *ngIf="
                filterLaufendesJahrFGByYear(laufendesJahrFG.value[key]).length
              ">
              <div *ngFor="
                  let be of filterLaufendesJahrFGByYear(
                    laufendesJahrFG.value[key]
                  );
                  let i = index
                " class="erfahrungen-card" [ngClass]="{ 'irrelevant-for-kfb': !isRelevantJob(be) }">
                <div class="left">
                  <p *ngIf="be.arbeitgeber">{{ be.arbeitgeber }}</p>
                  <p class="p-light" *ngIf="be.zeitraum_von && be.zeitraum_bis">
                    {{ be.zeitraum_von | date : "d.M.y" }} -
                    {{ be.zeitraum_bis | date : "d.M.y" }}
                  </p>
                  <p class="p-light" *ngIf="be.praktikum_art">
                    Praktikum: {{ be.praktikum_art }}
                  </p>
                  <p class="p-light" *ngIf="be.tage_beschaeftigt && isRelevantJob(be)">
                    {{ be.tage_beschaeftigt }} Tage - zur Berechnung der KFB Tage relevant
                  </p>
                  <p class="p-light" *ngIf="!isRelevantJob(be)">
                    {{ be.tage_beschaeftigt }} Tage - zur Berechnung der KFB Tage nicht relevant
                  </p>
                </div>
                <app-svgs type="trash" color="secondary" (click)="removeBeschaeftigung(key, i)">
                </app-svgs>
              </div>
            </div>
            <div>
              <app-button color="secondary" iconRight="plus" background="white"
                (click)="openAddBeschaeftigung(null, key)">
                Hinzufügen
              </app-button>
            </div>
            <div *ngIf="laufendesJahrFG.controls[key].invalid && submitAttempt" class="alert">
              Bitte gebe deine Zeiten für "{{ laufendes_jahr[key].label }}" an.
            </div>
          </div>
        </div>
      </span>
    </div>
    <p>
      Du bist verpflichtet, wahrheitsgemäße Angaben über deine bereits
      geleisteten Tag auf Kurzfristiger Beschäftigung im aktuellen Kalenderjahr
      zu machen.
    </p>
    <p>
      Solltest du keine korrekten Angaben machen und über die erlaubten 70 Tage
      hinaus arbeiten, läufst du Gefahr die nachberechneten Kosten für
      Rentenversicherung im Falle einer Prüfung durch die Deutsche
      Rentenversicherung vollumfänglich zurückzahlen zu müssen. Zusätzlich
      behalten wir uns vor, dir weitere Strafgebühren in Rechnung zu stellen.
    </p>
    <div class="tage-beschaeftigt">
      <label>
        Tage summiert, die du in diesem Jahr bereits beschäftigt warst:
        <div [ngClass]="{ 'kfb-input-error': approveLaufendesJahrError }">
          <input type="number" [(ngModel)]="tage_beschaeftigt_control"
            [placeholder]="tage_beschaeftigt_in_current_year" />
          <span>von 70 Tagen</span>
        </div>
      </label>
    </div>
    <app-button color="secondary-gradient" background="primary10" (click)="approveLaufendesJahr()">
      Angaben bestätigen
    </app-button>
  </div>
</app-modal>

<!-- add berufserfahrung modal -->
<app-modal *ngIf="addBerufserfahrungModal" (close)="closeArbeitsErfahrung()">
  <form (ngSubmit)="saveCurrentArbeitserfahrung()" [formGroup]="currentBerufserfahrung">
    <h4>Berufserfahrung</h4>
    <div>
      <p class="p-light">Veranstaltung</p>
      <input type="text" formControlName="veranstaltung" />
    </div>
    <div *ngIf="
        currentBerufserfahrung.get('veranstaltung')?.invalid &&
        modalSubmitAttempt
      " class="alert">
      Dieses Feld wird benötigt.
    </div>
    <div>
      <p class="p-light">Tätigkeit</p>
      <input type="text" formControlName="taetigkeit" />
    </div>
    <div *ngIf="
        currentBerufserfahrung.get('taetigkeit')?.invalid && modalSubmitAttempt
      " class="alert">
      Dieses Feld wird benötigt.
    </div>
    <div>
      <p class="p-light">Markenname</p>
      <input type="text" formControlName="markenname" />
    </div>
    <div *ngIf="
        currentBerufserfahrung.get('markenname')?.invalid && modalSubmitAttempt
      " class="alert">
      Dieses Feld wird benötigt.
    </div>
    <app-button color="secondary" background="white" type="submit">Speichern
    </app-button>
  </form>
</app-modal>

<!-- add beschäftigung modal -->
<app-modal *ngIf="addBeschaeftigungModal" (close)="closeBeschaeftigung()" [formGroup]="currentBeschaeftigungModalFG">
  <h4>
    {{ currentBeschaeftigungModalFG.value["label"] }}
  </h4>

  <!-- Arbeitgeber -->
  <div class="flex direction-column gap-7" *ngIf="currentBeschaeftigungModalFG.controls['arbeitgeber'].enabled">
    <label class="subscription" for="arbeitgeber">Arbeitgeber</label>
    <input type="text" name="arbeitgeber" id="arbeitgeber" formControlName="arbeitgeber" />
  </div>
  <div *ngIf="
      currentBeschaeftigungModalFG.controls['arbeitgeber']?.invalid &&
      modalSubmitAttempt
    " class="alert">
    Bitte gib einen Arbeitgeber an.
  </div>
  <div class="flex direction-column gap-7" *ngIf="currentBeschaeftigungModalFG.controls['praktikum_art'].enabled">
    <label for="art" class="subscription">Art</label>
    <app-toggle [formContrl]="currentBeschaeftigungModalFG.controls['praktikum_art']" color="darkblue-white"
      [toggles]="[{ label: 'Pflicht' }, { label: 'Freiwillig' }]"></app-toggle>
  </div>
  <!-- Zeitraum -->
  <div class="flex direction-column gap-7">
    <label for="" class="subscription">Zeitraum</label>
    <div class="von-bis">
      <input type="date" name="zeitraum_von" id="zeitraum_von" formControlName="zeitraum_von" [value]="
          currentBeschaeftigungModalFG.value['zeitraum_von'] | date : 'y-MM-dd'
        " [min]="startMinYear" [max]="startMaxYear" />
      <span>-</span>
      <input type="date" name="zeitraum_bis" id="zeitraum_bis" formControlName="zeitraum_bis" [value]="
          currentBeschaeftigungModalFG.value['zeitraum_bis'] | date : 'y-MM-dd'
        " [min]="endMinYear" [max]="endMaxYear" />
    </div>
  </div>
  <div *ngIf="
      currentBeschaeftigungModalFG.errors?.sameYearRequired &&
      modalSubmitAttempt
    " class="alert">
    {{ currentBeschaeftigungModalFG.errors?.sameYearRequired.text }}
  </div>
  <div *ngIf="
      (currentBeschaeftigungModalFG.controls.zeitraum_von.invalid ||
        currentBeschaeftigungModalFG.controls.zeitraum_bis.invalid) &&
      modalSubmitAttempt
    " class="alert">
    Start- und Enddatum sind erforderlich.
  </div>
  <!-- Tage auf kurzfristige Beschäftigung -->
  <div class="flex direction-column gap-7">
    <label for="" class="subscription">davon Werktage</label>
    <div class="tage-auf-kurz">
      <div class="minus" (click)="incrementTageBeschaeftigt(false)">
        <app-svgs type="minus" color="secondary"></app-svgs>
      </div>
      <input min="1" type="number"
        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" name="tage"
        id="tage" (change)="validateTageBeschaeftigt()" formControlName="tage_beschaeftigt" />
      <div class="plus" (click)="incrementTageBeschaeftigt(true)">
        <app-svgs type="plus" color="secondary"></app-svgs>
      </div>
    </div>
  </div>

  <app-button color="secondary" background="white" (click)="saveCurrentBeschaeftigung()">Speichern
  </app-button>
</app-modal>

<!-- login modal -->
<app-modal *ngIf="openLoginModal" (close)="closeLogin()">
  <h4>{{ loginModalTitle }}</h4>
  <div>
    <p class="p-light margin-bottom-20">{{ loginModalSubTitle }}</p>

    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="input-container">
        <label for="username">E-Mail Adresse</label>
        <input type="email" autocomplete="username" id="username" formControlName="email" />
      </div>
      <div class="input-container">
        <label for="current-password">Passwort</label>
        <input type="password" autocomplete="current-password" id="current-password" formControlName="password" />
      </div>
      <div class="alert" *ngIf="loginError">
        {{ loginError }}
      </div>
      <div class="input-container margin-top-24 button-container">
        <app-button type="button" chevron="right" color="secondary" (click)="login()" [disabled]="loginButtonDisabled"
          [isLoading]="loginButtonLoading" background="white">Einloggen</app-button>
        <app-button *ngIf="emailAlreadyExists" color="secondary" background="white" (click)="closeLogin()"
          [disabled]="loginButtonDisabled" [isLoading]="loginButtonLoading">Abbrechen und E-Mail-Adresse ändern
        </app-button>
      </div>
    </form>
  </div>
</app-modal>

<div class="apply">
  <div class="main bewerbungsstrecke" *ngIf="showBewerbungsstrecke">
    <app-header-w-backbtn-logo [backBtn]="true" (backBtnClicked)="location.back()"></app-header-w-backbtn-logo>
    <div class="content flex-center">
      <div>
        <h1 class="white margin-bottom-16 no-mobile-padding">Login</h1>
        <p class="white margin-bottom-16">
          Hast du noch nie für uns gearbeitet? Bewerbe dich hier auf diesen Job.
        </p>
        <div class="gold-link margin-bottom-36" (click)="showBewerbungsstrecke = false">
          Jetzt bewerben
        </div>

        <!-- login form start -->
        <div>
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="input-container">
              <label for="username" class="white">E-Mail Adresse</label>
              <input type="email" autocomplete="username" id="username" formControlName="email" />
            </div>
            <div class="input-container">
              <label for="current-password" class="white">Passwort</label>
              <input type="password" autocomplete="current-password" id="current-password" formControlName="password" />
            </div>
            <div class="alert" *ngIf="loginError">
              {{ loginError }}
            </div>
            <div class="input-container margin-top-24 button-container"></div>
          </form>
        </div>

        <!-- login form end -->

        <div class="flex gap-16">
          <app-button class="flex-grow-1" type="button" chevron="right" color="secondary-gradient" (click)="login()"
            [disabled]="loginButtonDisabled" [isLoading]="loginButtonLoading"
            background="primary">Einloggen</app-button>
        </div>
      </div>
    </div>
  </div>

  <div class="main" *ngIf="!showBewerbungsstrecke">
    <app-header-w-backbtn-logo [backBtn]="true" (backBtnClicked)="navigateBack()"
      *ngIf="!isSedEditFormOnly && !isPersEditFormOnly">
      <div>
        <p class="white" *ngIf="!isRegisterOnly">
          Bewerbung: {{ currentProject?.titel }}
        </p>
        <p class="white" *ngIf="isRegisterOnly">Registrierung</p>
        <p class="white p-light primary40">
          <span class="p-light">{{
            currentProject?.zeitraum_bis
            | formatTwoDates : currentProject?.zeitraum_bis
            }}</span>
        </p>
      </div>
    </app-header-w-backbtn-logo>
    <app-header-w-backbtn-logo [backBtn]="!projektLeiterNotTopteamer" (backBtnClicked)="navigateBack()"
      *ngIf="isSedEditFormOnly || isPersEditFormOnly">
    </app-header-w-backbtn-logo>
    <div *ngIf="isSedEditFormOnly || isPersEditFormOnly">
      <h1 *ngIf="isSedEditFormOnly" class="white">Sedcard</h1>
      <h1 *ngIf="isPersEditFormOnly" class="white">Persönliche Daten</h1>
      <h4 class="white margin-top-24" *ngIf="projektLeiterNotTopteamer">
        Herzlich willkommen! Bitte fülle als erstes deine Sedcard aus. Danach
        kann es weitergehen!
      </h4>
    </div>
    <div class="content" id="apply-scroll-target">
      <div class="ablauf" *ngIf="
          currentlyOpenCard == 1 &&
          !isSedEditFormOnly &&
          !isPersEditFormOnly &&
          !isRegisterOnly
        ">
        <h4 class="white">Ablauf</h4>
        <ol class="white">
          <li>Fülle das Bewerbungsformular aus und bestätige es.</li>
          <li>Wir sichten alle Bewerbungen und geben zeitnah Feedback</li>
          <li>
            Wir bereiten einen Vertrag vor, welchen du vervollständigst und
            unterschreibst.
          </li>
          <li>Los geht's zum Job</li>
        </ol>
      </div>

      <div class="white-bg">
        <app-data-collection-wrapper [title]="!isPersEditFormOnly ? '1. Persönliche Daten' : ''"
          [collapsed]="currentlyOpenCard != 1" [ngClass]="{
            flexGrow: currentlyOpenCard == 1,
            noTopOverlap: currentlyOpenCard < 1
          }" (titleClicked)="!isPersEditFormOnly ? ctaClicked(1) : null" [index]="1" [collapsedBg]="getCollapsedBg(1)"
          [titleNoPaddingBottom]="true" *ngIf="!isSedEditFormOnly">
          <form [formGroup]="persForm">
            <!-- ZUR PERSON -->
            <app-collapsable [dontShowCollapsableHeader]="true" [valid]="
                !persForm.controls['geschlecht'].invalid &&
                !persForm.controls['vorname'].invalid &&
                !persForm.controls['nachname'].invalid &&
                !persForm.controls['weitere_vornamen'].invalid &&
                !persForm.controls['email'].invalid &&
                !persForm.controls['tel'].invalid &&
                !persForm.controls['password'].invalid &&
                !persForm.controls['geburtsname'].invalid &&
                !persForm.controls['geburtsdatum'].invalid &&
                !persForm.controls['geburtsland'].invalid &&
                !persForm.controls['geburtsstadt'].invalid &&
                !persForm.controls['staatsangehoerigkeit_arr'].invalid &&
                !persForm.controls['ausweisnummer'].invalid
              ">
              <!-- !persForm.controls['staatsangehoerigkeit'].invalid && -->
              <h4>Zur Person</h4>

              <div *ngIf="!persForm.controls['geschlecht']?.disabled">
                <h5>Anrede</h5>
                <div class="sex-wrap">
                  <label [for]="g.value" class="checkbox" *ngFor="let g of geschlecht">
                    <input type="radio" [value]="g.value" [id]="g.value" formControlName="geschlecht"
                      autocomplete="sex" />
                    {{ g.label }}
                  </label>
                </div>
                <div *ngIf="persForm.get('geschlecht')?.invalid && submitAttempt" class="alert">
                  Bitte gib dein Geschlecht an.
                </div>
              </div>
              <div *ngIf="!persForm.controls['vorname']?.disabled">
                <h5>Vorname</h5>
                <input type="text" name="vorname" id="vorname" formControlName="vorname" autocomplete="given-name" />
                <div *ngIf="persForm.get('vorname')?.invalid && submitAttempt" class="alert">
                  Bitte gib deinen Vornamen an.
                </div>
              </div>
              <div *ngIf="!persForm.controls['weitere_vornamen']?.disabled">
                <h5>
                  Weitere Vornamen
                  <small class="subscription">Bitte so wie im Ausweis angeben.</small>
                </h5>
                <input type="text" name="weitere_vornamen" id="weitere_vornamen" formControlName="weitere_vornamen" />
                <div *ngIf="
                    persForm.get('weitere_vornamen')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib deine weiteren Vornamen an.
                </div>
              </div>
              <div *ngIf="!persForm.controls['nachname']?.disabled">
                <h5>Nachname</h5>
                <input type="text" name="nachname" id="nachname" formControlName="nachname"
                  autocomplete="family-name" />
                <div *ngIf="persForm.get('nachname')?.invalid && submitAttempt" class="alert">
                  Bitte gib deinen Nachnamen an.
                </div>
              </div>

              <div class="plz-ort-wrap">
                <div *ngIf="!persForm.controls['plz']?.disabled && !isTopteamer">
                  <h5>PLZ</h5>
                  <input type="text" name="plz" id="plz" formControlName="plz" autocomplete="postal-code" />
                </div>
                <div *ngIf="!persForm.controls['ort']?.disabled">
                  <h5>Ort</h5>
                  <input type="text" name="ort" id="ort" formControlName="ort" autocomplete="address-level2" />
                </div>
              </div>

              <div *ngIf="persForm.get('plz')?.invalid && submitAttempt" class="alert">
                Bitte gib eine Postleitzahl an.
              </div>
              <div *ngIf="persForm.get('ort')?.invalid && submitAttempt" class="alert">
                Bitte gib einen Ort an.
              </div>

              <div *ngIf="!persForm.controls['geburtsname']?.disabled">
                <h5>Geburtsname</h5>
                <input type="text" name="geburtsname" id="geburtsname" formControlName="geburtsname"
                  autocomplete="family-name" />
                <div *ngIf="persForm.get('geburtsname')?.invalid && submitAttempt" class="alert">
                  Bitte gib deinen Geburtsnamen an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['geburtsdatum']?.disabled">
                <h5>Geburtsdatum</h5>
                <app-dob-picker *ngIf="persForm && persForm.controls['geburtsdatum']"
                  [formCntrl]="persForm.controls['geburtsdatum']"></app-dob-picker>
                <div *ngIf="persForm.get('geburtsdatum')?.invalid && submitAttempt" class="alert">
                  Bitte gib deinen Geburtstag an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['geburtsland']?.disabled">
                <h5>Geburtsland</h5>
                <ng-select [items]="getStaaten" bindLabel="label" bindValue="id" formControlName="geburtsland"
                  autocomplete="country">
                </ng-select>

                <div *ngIf="persForm.get('geburtsland')?.invalid && submitAttempt" class="alert">
                  Bitte gib dein Geburtsland an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['geburtsstadt']?.disabled">
                <h5>Geburtsort</h5>
                <input type="text" name="geburtsstadt" id="geburtsstadt" formControlName="geburtsstadt" />
                <div *ngIf="persForm.get('geburtsstadt')?.invalid && submitAttempt" class="alert">
                  Bitte gib deine Geburtsort an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['staatsangehoerigkeit_arr']?.disabled">
                <h5>Staatsangehörigkeit</h5>
                <ng-select [items]="getStaatsstaatsangehoearigkeit" [multiple]="true"
                  formControlName="staatsangehoerigkeit_arr">
                </ng-select>

                <div *ngIf="
                    persForm.get('staatsangehoerigkeit_arr')?.invalid &&
                    submitAttempt
                  " class="alert">
                  Bitte gib deine Staatsangehörigkeit(en) an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['ausweisnummer']?.disabled">
                <h5>Ausweisnummer</h5>
                <input type="text" name="ausweisnummer" id="ausweisnummer" formControlName="ausweisnummer" />
                <div *ngIf="
                    persForm.get('ausweisnummer')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib deine Ausweisnummer an.
                </div>
              </div>

              <!-- upload ausweis fotos from both the back and the front -->
              <div class="ausweis-wrap" *ngIf="
                  !persForm.controls['ausweis_vorne']?.disabled ||
                  !persForm.controls['ausweis_hinten']?.disabled
                ">
                <h4>Ausweisdokument hochladen</h4>
                <div *ngIf="!persForm.controls['ausweis_vorne']?.disabled">
                  <h5>Vorderseite</h5>
                  <td class="a">
                    <app-fileupload [uploadFiles]="persForm.value['ausweis_vorne']" (uploadFilesChange)="
                        persForm.controls['ausweis_vorne'].setValue($event)
                      " [maxFiles]="1" [hideAddButtonAfterLimit]="true">
                    </app-fileupload>
                    <div *ngIf="
                        persForm.get('ausweis_vorne')?.invalid && submitAttempt
                      " class="alert">
                      Bitte lade ein Bild der Vorderseite deines Ausweises hoch.
                    </div>
                  </td>
                </div>

                <div *ngIf="!persForm.controls['ausweis_hinten']?.disabled">
                  <h5>Rückseite</h5>
                  <td class="a">
                    <app-fileupload [uploadFiles]="persForm.value['ausweis_hinten']" (uploadFilesChange)="
                        persForm.controls['ausweis_hinten'].setValue($event)
                      " [maxFiles]="1" [hideAddButtonAfterLimit]="true">
                    </app-fileupload>
                    <div *ngIf="
                        persForm.get('ausweis_hinten')?.invalid && submitAttempt
                      " class="alert">
                      Bitte lade ein Bild der Rückseite deines Ausweises hoch.
                    </div>
                  </td>
                </div>
              </div>

              <div *ngIf="!isTopteamer && !persForm.controls['email']?.disabled">
                <h5>E-Mail</h5>
                <input type="email" name="email" id="email" formControlName="email" autocomplete="email" />
                <div *ngIf="persForm.get('email')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine gültige E-Mail-Adresse an.
                </div>
                <div *ngIf="persForm.get('email')?.errors?.['emailExists']" class="alert">
                  Diese E-Mail-Adresse ist bereits registriert.
                  <span class="gold-link" (click)="showBewerbungsstrecke = true">
                    Bitte melde dich an.</span>
                </div>
              </div>
              <div *ngIf="!isTopteamer && !persForm.controls['tel']?.disabled">
                <h5>Telefon</h5>
                <input placeholder="+49 176 123456" type="text" name="tel" id="tel" formControlName="tel"
                  autocomplete="tel" />
                <div *ngIf="persForm.get('tel')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine gültige Telefonnummer an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['password']?.disabled">
                <h5>Passwort</h5>
                <input type="password" name="password" id="password" formControlName="password"
                  autocomplete="new-password" />
                <p class="subscription">
                  Damit kannst du dich jederzeit einloggen und über den Status
                  der Bewerbung informieren.
                </p>
                <div *ngIf="persForm.get('password')?.invalid && submitAttempt" class="alert">
                  Bitte gib ein Passwort an.
                </div>
              </div>
            </app-collapsable>

            <!-- KONTAKT -->
            <app-collapsable *ngIf="isTopteamer" [valid]="
                !persForm.controls['strasse'].invalid &&
                !persForm.controls['hausnr'].invalid &&
                !persForm.controls['plz'].invalid &&
                !persForm.controls['ort'].invalid &&
                !persForm.controls['addresszusatz'].invalid &&
                !persForm.controls['wohnland'].invalid &&
                (!isTopteamer || !persForm.controls['email'].invalid) &&
                (!isTopteamer || !persForm.controls['tel'].invalid)
              ">
              <h4>Kontakt (aktuelle Meldeadresse)</h4>

              <div class="strasse-wrap">
                <div *ngIf="!persForm.controls['strasse']?.disabled">
                  <h5>Straße</h5>
                  <input type="text" name="strasse" id="strasse" formControlName="strasse"
                    autocomplete="address-line1" />
                </div>
                <div *ngIf="!persForm.controls['hausnr']?.disabled">
                  <h5>Hausnr.</h5>
                  <input type="text" name="hausnr" id="hausnr" formControlName="hausnr" autocomplete="address-line1" />
                </div>
              </div>
              <div *ngIf="persForm.get('strasse')?.invalid && submitAttempt" class="alert">
                Bitte gib eine Straße an.
              </div>
              <div *ngIf="persForm.get('hausnr')?.invalid && submitAttempt" class="alert">
                Bitte gib eine Hausnummer an.
              </div>

              <div class="plz-ort-wrap">
                <div *ngIf="!persForm.controls['plz']?.disabled">
                  <h5>PLZ</h5>
                  <input type="text" name="plz" id="plz" formControlName="plz" autocomplete="postal-code" />
                </div>
                <div *ngIf="!persForm.controls['ort']?.disabled">
                  <h5>Ort</h5>
                  <input type="text" name="ort" id="ort" formControlName="ort" autocomplete="address-level2" />
                </div>
              </div>

              <div *ngIf="persForm.get('plz')?.invalid && submitAttempt" class="alert">
                Bitte gib eine Postleitzahl an.
              </div>
              <div *ngIf="persForm.get('ort')?.invalid && submitAttempt" class="alert">
                Bitte gib einen Ort an.
              </div>

              <div *ngIf="!persForm.controls['wohnland']?.disabled">
                <h5>Wohnland</h5>
                <ng-select [items]="getStaaten" bindLabel="label" bindValue="id" formControlName="wohnland"
                  autocomplete="country">
                </ng-select>

                <div *ngIf="persForm.get('wohnland')?.invalid && submitAttempt" class="alert">
                  Bitte gib dein Wohnland an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['addresszusatz']?.disabled">
                <h5>Addresszusatz</h5>
                <input type="text" name="addresszusatz" id="addresszusatz" formControlName="addresszusatz" />
                <div *ngIf="
                    persForm.get('addresszusatz')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib einen Addresszusatz an.
                </div>
              </div>

              <div *ngIf="isTopteamer && !persForm.controls['email']?.disabled">
                <h5>E-Mail</h5>
                <input type="email" name="email" id="email" formControlName="email" autocomplete="email" />
                <div *ngIf="persForm.get('email')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine gültige E-Mail-Adresse an.
                </div>
              </div>
              <div *ngIf="isTopteamer && !persForm.controls['tel']?.disabled">
                <h5>Telefon</h5>
                <input placeholder="+49 176 123456" type="text" name="tel" id="tel" formControlName="tel"
                  autocomplete="tel" />
                <div *ngIf="persForm.get('tel')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine gültige Telefonnummer an.
                </div>
              </div>
            </app-collapsable>

            <!-- Abschlüsse -->
            <app-collapsable *ngIf="isTopteamer" [valid]="
                !persForm.controls['hoechster_allgemeiner_schulabschluss']
                  .invalid &&
                !persForm.controls['hoechster_beruflicher_abschluss'].invalid
              ">
              <h4>Abschlüsse</h4>

              <div *ngIf="
                  !persForm.controls['hoechster_allgemeiner_schulabschluss']
                    ?.disabled
                ">
                <h5>Höchster allgemeiner Schulabschluss</h5>

                <select name="hoechster_allgemeiner_schulabschluss" id="hoechster_allgemeiner_schulabschluss"
                  formControlName="hoechster_allgemeiner_schulabschluss">
                  <option value=""></option>
                  <option *ngFor="
                      let abschluss of hoechster_allgemeiner_schulabschluss
                    " [ngValue]="abschluss">
                    {{ abschluss }}
                  </option>
                </select>

                <div *ngIf="
                    persForm.get('hoechster_allgemeiner_schulabschluss')
                      ?.invalid && submitAttempt
                  " class="alert">
                  Wähle eine Option.
                </div>
              </div>

              <div *ngIf="
                  !persForm.controls['hoechster_beruflicher_abschluss']
                    ?.disabled
                ">
                <h5>Höchster beruflicher Abschluss</h5>
                <select name="hoechster_beruflicher_abschluss" id="hoechster_beruflicher_abschluss"
                  formControlName="hoechster_beruflicher_abschluss">
                  <option value=""></option>
                  <option *ngFor="let abschluss of hoechster_beruflicher_abschluss" [ngValue]="abschluss">
                    {{ abschluss }}
                  </option>
                </select>
                <div *ngIf="
                    persForm.get('hoechster_beruflicher_abschluss')?.invalid &&
                    submitAttempt
                  " class="alert">
                  Wähle eine Option.
                </div>
              </div>
            </app-collapsable>

            <!-- STATUS BEI ARBEITSBEGINN -->
            <app-collapsable [valid]="
                !persForm.controls['arbeitsbeginn_status'].invalid &&
                !persForm.controls['arbeitsbeginn_status_additional'].invalid &&
                !persForm.controls['existiert_hauptarbeitgeber'].invalid &&
                !persForm.controls['aufenthaltstitel'].invalid &&
                !persForm.controls['aufenthaltstitel_nachweis'].invalid &&
                !persForm.controls['gewerbeschein'].invalid &&
                !persForm.controls['gewerbeschein_nachweis'].invalid &&
                !persForm.controls['gesundheitszeugnis'].invalid &&
                !persForm.controls['gesundheitszeugnis_nachweis'].invalid
              ">
              <h4>Status bei Arbeitsbeginn im laufenden Kalenderjahr</h4>
              <button (click)="infoModals.status = true" class="modal-button">
                Infos zur Statusangabe findest du hier
              </button>
              <div>
                <app-arbeitsbeginn-status [persForm]="persForm" [submitAttempt]="submitAttempt"
                  (isUploading)="isUploading = $event">
                </app-arbeitsbeginn-status>
              </div>
            </app-collapsable>

            <!-- LAUFENDES JAHR -->
            <app-collapsable *ngIf="isTopteamer" [valid]="true">
              <h4 *ngIf="!isPersEditFormOnly">
                {{
                projectYears.length > 1 ? "Laufende Jahre" : "Laufendes Jahr"
                }}
                {{ projectYears.join(", ") }}
              </h4>
              <h4 *ngIf="isPersEditFormOnly">Alle Beschäftigungen</h4>
              <div>
                <p class="subscription" *ngIf="!isPersEditFormOnly">
                  Beschäftigungen
                  {{ projectYears.length > 1 ? "in den Jahren" : "im Jahr" }}
                  {{ projectYears.join(", ") }}. Pro Kalenderjahr darfst du max.
                  70 Tage kurzfristig Beschäftigt sein.
                </p>
              </div>
              <div>
                <div class="tags" [formGroup]="laufendesJahrCheckBoxes">
                  <span *ngFor="let item of laufendes_jahr_array">
                    <span>
                      <input type="checkbox" [name]="item.formControlName" [id]="item.formControlName + '_lj'"
                        [formControlName]="item.formControlName" />
                      <label [for]="item.formControlName + '_lj'">{{
                        item.label
                        }}</label>
                    </span>
                  </span>
                </div>
                <div *ngIf="
                    persForm.get('laufendes_jahr')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib deine Beschäftigungen
                  {{ projectYears.length > 1 ? "in den Jahren" : "im Jahr" }}
                  {{ projectYears.join(", ") }} an.
                </div>

                <span formGroupName="laufendes_jahr">
                  <div *ngFor="let key of laufendes_jahr_enabled_FG_keys" class="additional-container">
                    <div *ngIf="true">
                      <div class="additional">
                        <div>
                          <h4>{{ laufendes_jahr[key].label }}</h4>
                          <p class="subscription">
                            Alle Beschäftigungen
                            <span *ngIf="!this.isPersEditFormOnly">{{
                              projectYears.length > 1
                              ? "in den Jahren"
                              : "im Jahr"
                              }}</span>
                            {{ projectYears.join(", ") }} eintragen.
                          </p>
                        </div>
                        <div class="berufserfahrung" *ngIf="
                            filterLaufendesJahrFGByYear(
                              laufendesJahrFG.value[key]
                            ).length
                          ">
                          <div *ngFor="
                              let be of filterLaufendesJahrFGByYear(
                                laufendesJahrFG.value[key]
                              );
                              let i = index
                            " class="erfahrungen-card">
                            <div class="left">
                              <p *ngIf="be.arbeitgeber">{{ be.arbeitgeber }}</p>
                              <p class="p-light" *ngIf="be.zeitraum_von && be.zeitraum_bis">
                                {{ be.zeitraum_von | date : "d.M.y" }} -
                                {{ be.zeitraum_bis | date : "d.M.y" }}
                              </p>
                              <p class="p-light" *ngIf="be.praktikum_art && key == 'Praktikum'">
                                Praktikum: {{ be.praktikum_art }}
                              </p>
                              <p class="p-light" *ngIf="be.tage_beschaeftigt">
                                {{ be.tage_beschaeftigt }} Tage
                              </p>
                            </div>
                            <app-svgs type="trash" color="secondary" (click)="removeBeschaeftigung(key, i)">
                            </app-svgs>
                          </div>
                        </div>
                        <div>
                          <app-button color="secondary" iconRight="plus" background="white"
                            (click)="openAddBeschaeftigung(null, key)">
                            Hinzufügen
                          </app-button>
                        </div>
                        <div *ngIf="
                            laufendesJahrFG.controls[key].invalid &&
                            submitAttempt
                          " class="alert">
                          Bitte gib deine Zeiten für "{{
                          laufendes_jahr[key].label
                          }}" an.
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div *ngFor="let ljt of laufendesJahrTage" [ngClass]="{ alert: ljt.tage_beschaeftigt > 70 }">
                <b>Gesamt {{ ljt.year }}: {{ ljt.tage_beschaeftigt }}</b>/70 Tage
              </div>
            </app-collapsable>

            <!-- RECHTLICHES -->
            <app-collapsable *ngIf="isTopteamer" [valid]="
                !persForm.controls['steueridentifikationsnummer'].invalid &&
                !persForm.controls['steuerklasse'].invalid &&
                !persForm.controls['sozialversicherungsnummer'].invalid &&
                !persForm.controls['krankenkasse_typ'].invalid &&
                !persForm.controls['krankenkasse_name'].invalid &&
                !persForm.controls['familienstand'].invalid &&
                !persForm.controls['religionszugehoerigkeit'].invalid &&
                !persForm.controls['kinderfreibetrag'].invalid
              ">
              <h4>Rechtliches</h4>

              <div *ngIf="
                  !persForm.controls['steueridentifikationsnummer']?.disabled
                ">
                <h5>
                  {{
                  persForm.value.wohnland == "CH"
                  ? "AHV-Nummer"
                  : "Steueridentifikationsnummer (SteuerID)"
                  }}
                </h5>
                <button (click)="infoModals.taxId = true" class="modal-button">
                  Woher bekomme ich meine Steueridentifikationsnummer (SteuerID)
                </button>
                <input type="text" name="steueridentifikationsnummer" id="steueridentifikationsnummer"
                  formControlName="steueridentifikationsnummer" [placeholder]="
                    persForm.value.wohnland == 'CH'
                      ? '756.1234.5678.95'
                      : persForm.value.wohnland == 'DE'
                      ? '12345678000'
                      : persForm.value.wohnland == 'AT'
                      ? '03-999/9032'
                      : ''
                  " />
                <div *ngIf="
                    persForm.get('steueridentifikationsnummer')?.invalid &&
                    submitAttempt
                  " class="alert">
                  Bitte gib eine gültige
                  {{
                  persForm.value.wohnland == "CH"
                  ? "AHV-Nummer"
                  : "Steueridentifikationsnummer"
                  }}
                  für Ihr Wohnland an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['steuerklasse']?.disabled">
                <h5>Steuerklasse für die Anstellung bei Topteam</h5>
                <button (click)="infoModals.taxClass = true" class="modal-button">
                  Infos zur Steuerklasse findest du hier
                </button>
                <app-toggle color="darkblue-primary10" [toggles]="steuerKlasseToggles"
                  [formContrl]="persForm!.controls['steuerklasse']"></app-toggle>
              </div>

              <div *ngIf="
                  !persForm.controls['sozialversicherungsnummer']?.disabled
                ">
                <h5>Sozialversicherungsnummer</h5>
                <button (click)="infoModals.insurance = true" class="modal-button">
                  Infos zur Sozialversicherungsnummer findest du hier
                </button>
                <input type="text" name="sozialversicherungsnummer" id="sozialversicherungsnummer"
                  formControlName="sozialversicherungsnummer" />
                <div *ngIf="
                    persForm.get('sozialversicherungsnummer')?.invalid &&
                    submitAttempt
                  " class="alert">
                  Bitte gib eine gültige
                  {{
                  persForm.value.wohnland == "CH"
                  ? "AHV-Nummer"
                  : "Sozialversicherungsnummer"
                  }}
                  für Ihr Wohnland an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['krankenkasse_typ']?.disabled">
                <h5>Krankenkasse</h5>
                <app-toggle color="darkblue-primary10" [toggles]="[
                    { label: 'gesetzlich' },
                    { label: 'privat' },
                    { label: 'andere' }
                  ]" [formContrl]="persForm.controls['krankenkasse_typ']"></app-toggle>
              </div>

              <div *ngIf="!persForm.controls['krankenkasse_name']?.disabled">
                <h5>Name der Krankenkasse</h5>
                <ng-select *ngIf="getKK.length" [items]="getKK" formControlName="krankenkasse_name">
                </ng-select>
                <input type="text" formControlName="krankenkasse_name" id="krankenkasse_name" name="krankenkasse_name"
                  *ngIf="!getKK.length" />

                <div *ngIf="
                    persForm.get('krankenkasse_name')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib eine Krankenkasse an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['familienstand']?.disabled">
                <h5>Familienstand</h5>
                <app-toggle color="darkblue-primary10" [toggles]="[{ label: 'ledig' }, { label: 'verheiratet' }]"
                  [formContrl]="persForm.controls['familienstand']"></app-toggle>
              </div>

              <div *ngIf="!persForm.controls['religionszugehoerigkeit']?.disabled">
                <h5>Religionszugehörigkeit</h5>
                <ng-select [items]="getKonfessionen" formControlName="religionszugehoerigkeit">
                </ng-select>
                <div *ngIf="
                    persForm.get('religionszugehoerigkeit')?.invalid &&
                    submitAttempt
                  " class="alert">
                  Bitte gib eine Religionszugehörigkeit an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['kinderfreibetrag']?.disabled">
                <h5>Kinderfreibetrag?</h5>
                <div class="sex-wrap">
                  <label for="kinderfreibetrag_Ja" class="checkbox">
                    <input type="radio" [value]="true" id="kinderfreibetrag_Ja" formControlName="kinderfreibetrag" />
                    Ja
                  </label>
                  <label for="kinderfreibetrag_Nein" class="checkbox">
                    <input type="radio" [value]="false" id="kinderfreibetrag_Nein" formControlName="kinderfreibetrag" />
                    Nein
                  </label>
                </div>
              </div>
            </app-collapsable>

            <!-- BANKVERBINDUNG -->
            <app-collapsable *ngIf="isTopteamer" [valid]="
                !persForm.controls['iban'].invalid &&
                !persForm.controls['bic'].invalid
              ">
              <h4>Bankverbindung</h4>
              <div *ngIf="!persForm.controls['iban']?.disabled">
                <h5>IBAN</h5>
                <input type="text" name="iban" id="iban" formControlName="iban" />
                <div *ngIf="persForm.get('iban')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine gültige IBAN an.
                </div>
              </div>

              <div *ngIf="!persForm.controls['bic']?.disabled">
                <h5>BIC</h5>
                <input type="text" name="bic" id="bic" formControlName="bic" />
                <div *ngIf="persForm.get('bic')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine gültige BIC an.
                </div>
              </div>
            </app-collapsable>
          </form>
        </app-data-collection-wrapper>

        <!-- sed card -->
        <app-data-collection-wrapper [title]="!isSedEditFormOnly ? '2. Sedcard' : ''"
          [collapsed]="currentlyOpenCard != 2" [ngClass]="{
            flexGrow: currentlyOpenCard == 2,
            noTopOverlap: currentlyOpenCard < 2
          }" (titleClicked)="ctaClicked(2)" [index]="2" [collapsedBg]="getCollapsedBg(2)" *ngIf="!isPersEditFormOnly">
          <form [formGroup]="sedForm">
            <!-- Äusseres -->
            <app-collapsable [dontShowCollapsableHeader]="!isTopteamer" [valid]="
                !sedForm.controls['haarfarbe'].invalid &&
                !sedForm.controls['koerpergroesse'].invalid &&
                !sedForm.controls['gewicht'].invalid &&
                (!isTopteamer || !sedForm.controls['shirtgroesse'].invalid)
              "
            >
              <h4>Äusseres</h4>

              <div *ngIf="!sedForm.controls['haarfarbe']?.disabled">
                <h5>Haarfarbe</h5>
                <select name="haarfarbe" id="haarfarbe" formControlName="haarfarbe">
                  <option value=""></option>
                  <option *ngFor="let farbe of haarfarben" [ngValue]="farbe">
                    {{ farbe }}
                  </option>
                </select>
                <div *ngIf="sedForm.get('haarfarbe')?.invalid && submitAttempt" class="alert">
                  Bitte gib eine Haarfarbe an.
                </div>
              </div>

              <div *ngIf="!sedForm.controls['koerpergroesse']?.disabled">
                <h5>Körpergröße</h5>
                <div class="cm-wrap">
                  <input type="number"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                    step="1" min="0" max="300" name="koerpergroesse" id="koerpergroesse"
                    formControlName="koerpergroesse" class="cm" />
                </div>
                <div *ngIf="
                    sedForm.get('koerpergroesse')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib deine Körpergröße an.
                </div>
              </div>

              <div *ngIf="!sedForm.controls['gewicht']?.disabled">
                <h5>Gewicht</h5>
                <div class="cm-wrap kg">
                  <input type="number"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                    step="1" min="0" max="300" name="gewicht" id="gewicht" formControlName="gewicht" class="kg" />
                </div>
                <div *ngIf="sedForm.get('gewicht')?.invalid && submitAttempt" class="alert">
                  Bitte gib dein Gewicht an.
                </div>
              </div>

              <div *ngIf="
                  !isTopteamer && !sedForm.controls['shirtgroesse']?.disabled
                ">
                <h5>Shirtgröße</h5>
                <app-toggle color="gold-grey" [toggles]="shirtToggles"
                  [formContrl]="sedForm.controls['shirtgroesse']"></app-toggle>
                <div *ngIf="sedForm.get('shirtgroesse')?.invalid && submitAttempt" class="alert">
                  Bitte gib deine Shirtgröße an.
                </div>
              </div>
            </app-collapsable>

            <!-- Konfektionsgrössen -->
            <app-collapsable *ngIf="isTopteamer" [valid]="
                (isTopteamer || !sedForm.controls['shirtgroesse'].invalid) &&
                !sedForm.controls['jacke_sacko'].invalid &&
                !sedForm.controls['hose_rock'].invalid &&
                !sedForm.controls['jeansweite'].invalid &&
                !sedForm.controls['jeanslaenge'].invalid &&
                !sedForm.controls['schuhgroesse'].invalid &&
                !sedForm.controls['guertellaenge'].invalid &&
                !sedForm.controls['kragenweite'].invalid
              ">
              <h4>Konfektionsgrössen</h4>

              <div *ngIf="
                  isTopteamer && !sedForm.controls['shirtgroesse']?.disabled
                ">
                <h5>Shirtgröße</h5>
                <app-toggle color="gold-grey" [toggles]="shirtToggles"
                  [formContrl]="sedForm.controls['shirtgroesse']"></app-toggle>
                <div *ngIf="sedForm.get('shirtgroesse')?.invalid && submitAttempt" class="alert">
                  Bitte gib deine Shirtgröße an.
                </div>
              </div>

              <div class="two-cols">
                <div *ngIf="!sedForm.controls['jacke_sacko']?.disabled" class="left">
                  <h5>Jacke / Sacko</h5>
                  <select name="jacke_sacko" id="jacke_sacko" formControlName="jacke_sacko">
                    <option value=""></option>
                    <option *ngFor="let groesse of jacke_sacko_groessen" [ngValue]="groesse">
                      {{ groesse }}
                    </option>
                  </select>
                </div>

                <div *ngIf="!sedForm.controls['hose_rock']?.disabled" class="right">
                  <h5>Hose / Rock</h5>
                  <select name="hose_rock" id="hose_rock" formControlName="hose_rock">
                    <option value=""></option>
                    <option *ngFor="let groesse of hose_rock_groessen" [ngValue]="groesse">
                      {{ groesse }}
                    </option>
                  </select>
                </div>

                <div *ngIf="!sedForm.controls['jeansweite']?.disabled" class="left">
                  <h5>Jeansweite</h5>
                  <select name="jeansweite" id="jeansweite" formControlName="jeansweite">
                    <option value=""></option>
                    <option *ngFor="let groesse of jeansweite" [ngValue]="groesse">
                      {{ groesse }}
                    </option>
                  </select>
                </div>

                <div *ngIf="!sedForm.controls['jeanslaenge']?.disabled" class="right">
                  <h5>Jeanslänge</h5>
                  <select name="jeanslaenge" id="jeanslaenge" formControlName="jeanslaenge">
                    <option value=""></option>
                    <option *ngFor="let groesse of jeanslaenge" [ngValue]="groesse">
                      {{ groesse }}
                    </option>
                  </select>
                </div>
              </div>
              <div *ngIf="
                  (sedForm.get('jacke_sacko')?.invalid ||
                    sedForm.get('hose_rock')?.invalid ||
                    sedForm.get('jeansweite')?.invalid ||
                    sedForm.get('jeanslaenge')?.invalid) &&
                  submitAttempt
                " class="alert">
                Bitte gib deine Größen an.
              </div>

              <!-- Schuhgröße -->
              <div *ngIf="!sedForm.controls['schuhgroesse']?.disabled" class="left">
                <h5>Schuhgröße</h5>
                <select name="schuhgroesse" id="schuhgroesse" formControlName="schuhgroesse">
                  <option value=""></option>
                  <option *ngFor="let groesse of schuhgroesse" [ngValue]="groesse">
                    {{ groesse }}
                  </option>
                </select>
              </div>
              <div *ngIf="sedForm.get('schuhgroesse')?.invalid && submitAttempt" class="alert">
                Bitte gib deine Schuhgröße an.
              </div>

              <!-- Gürtellänge -->
              <div *ngIf="!sedForm.controls['guertellaenge']?.disabled" class="left">
                <h5>Gürtellänge</h5>
                <select name="guertellaenge" id="guertellaenge" formControlName="guertellaenge">
                  <option value=""></option>
                  <option *ngFor="let laenge of guertellaenge" [ngValue]="laenge">
                    {{ laenge }}
                  </option>
                </select>
              </div>
              <div *ngIf="sedForm.get('guertellaenge')?.invalid && submitAttempt" class="alert">
                Bitte gib deine Gürtellänge an.
              </div>
              <!-- Kragenweite -->
              <div *ngIf="
                  !sedForm.controls['kragenweite']?.disabled &&
                  ['m', 'd'].includes(
                    persForm?.controls?.geschlecht?.value || ''
                  )
                " class="left">
                <h5>Kragenweite</h5>
                <select name="kragenweite" id="kragenweite" formControlName="kragenweite">
                  <option value=""></option>
                  <option *ngIf="persForm?.controls?.geschlecht?.value == 'd'" [ngValue]="0">
                    keine Angabe
                  </option>
                  <option *ngFor="let weite of kragenweite" [ngValue]="weite">
                    {{ weite }}
                  </option>
                </select>
              </div>
              <div *ngIf="sedForm.get('kragenweite')?.invalid && submitAttempt" class="alert">
                Bitte gib deine Kragenweite an.
              </div>
            </app-collapsable>

            <!-- Fotos -->
            <app-collapsable [dontShowCollapsableHeader]="!isTopteamer" [valid]="!sedForm.controls['fotos'].invalid">
              <h4>Fotos</h4>
              <div>
                <h5 *ngIf="!isTopteamer">Fotos</h5>
                <p class="subscription margin-bottom-18">
                  Bitte beachte unsere
                  <span class="foto-guidelines" (click)="fotoGuidelinesOpen = true">Foto-Guidelines</span>
                </p>
                <app-fileupload [uploadFiles]="sedForm.value['fotos']" (uploadFilesChange)="
                    sedForm.controls['fotos'].setValue($event)
                  " (isUploading)="isUploading = $event" [maxFiles]="5">
                </app-fileupload>
                <p class="subscription margin-top-12">max. 5 Fotos</p>
                <div *ngIf="sedForm.get('fotos')?.invalid && submitAttempt" class="alert">
                  Bitte lade mindestens ein Foto hoch.
                </div>
              </div>
            </app-collapsable>

            <!-- Mobilität -->
            <app-collapsable *ngIf="isTopteamer" [valid]="
                !sedForm.controls['fuehrerschein'].invalid ||
                !sedForm.controls['vorhandene_mobilitaet'].invalid
              ">
              <h4>Mobilität</h4>

              <div *ngIf="!sedForm.controls['fuehrerschein']?.disabled">
                <h5>Führerschein vorhanden?</h5>
                <div class="flex">
                  <label for="Ja_fuehrer" class="checkbox">
                    <input type="radio" name="fuehrerschein" id="Ja_fuehrer" formControlName="fuehrerschein"
                      [value]="true" />
                    Ja
                  </label>
                  <label for="Nein_fuehrer" class="checkbox">
                    <input type="radio" name="fuehrerschein" id="Nein_fuehrer" formControlName="fuehrerschein"
                      [value]="false" />
                    Nein
                  </label>
                </div>
                <div *ngIf="sedForm.get('fuehrerschein')?.invalid && submitAttempt" class="alert">
                  Ist ein Führerschein vorhanden?
                </div>
              </div>

              <div *ngIf="!sedForm.controls['vorhandene_mobilitaet']?.disabled">
                <h5>Vorhandene Mobilität</h5>
                <div class="tags" formGroupName="vorhandene_mobilitaet">
                  <span>
                    <input type="checkbox" id="vorhandene_mobilitaet_PKW" name="vorhandene_mobilitaet_PKW" value="PKW"
                      formControlName="PKW" />
                    <label for="vorhandene_mobilitaet_PKW">PKW</label>
                  </span>
                  <span>
                    <input type="checkbox" id="vorhandene_mobilitaet_Bahncard" name="vorhandene_mobilitaet_Bahncard"
                      value="Bahncard" formControlName="Bahncard" />
                    <label for="vorhandene_mobilitaet_Bahncard">Bahncard</label>
                  </span>
                </div>
              </div>
            </app-collapsable>

            <!-- Sprachen -->
            <app-collapsable *ngIf="isTopteamer" [valid]="!sedForm.controls['sprachen'].invalid"
              formGroupName="sprachen">
              <h4>Sprachen</h4>
              <div>
                <div class="two-cols">
                  <p class="subscription left">3 - Schulkenntnisse</p>
                  <p class="subscription right">2 - Gute Kenntnisse</p>
                  <p class="subscription left">1 - Fließend</p>
                  <p class="subscription right">1+ - Muttersprachniveau</p>
                </div>
              </div>

              <div>
                <h5>Deutsch</h5>
                <app-toggle color="darkblue-primary10" [toggles]="sprachToggles"
                  [formContrl]="sprachenFG.controls['deutsch']"></app-toggle>
              </div>

              <div>
                <h5>Englisch</h5>
                <app-toggle color="darkblue-primary10" [toggles]="sprachToggles"
                  [formContrl]="sprachenFG.controls['englisch']"></app-toggle>
              </div>

              <div>
                <h5>Spanisch</h5>
                <app-toggle color="darkblue-primary10" [toggles]="sprachToggles"
                  [formContrl]="sprachenFG.controls['spanisch']"></app-toggle>
              </div>

              <div>
                <h5>Französisch</h5>
                <app-toggle color="darkblue-primary10" [toggles]="sprachToggles"
                  [formContrl]="sprachenFG.controls['franzoesisch']"></app-toggle>
              </div>

              <div>
                <h5>Italienisch</h5>
                <app-toggle color="darkblue-primary10" [toggles]="sprachToggles"
                  [formContrl]="sprachenFG.controls['italienisch']"></app-toggle>
              </div>

              <div *ngFor="let item of sprachenFG.controls['weitere'].value">
                <h5>{{ item.label }}</h5>
                <app-toggle color="darkblue-primary10" [toggles]="sprachToggles" (indexSelectedChange)="
                    item.level = sprachToggles[$event].label
                  " [indexSelected]="getSprachToggleIndex(item.level)"></app-toggle>
              </div>

              <!-- add sprache -->
              <div>
                <h5>Weitere</h5>
                <div class="add-sprache">
                  <input type="text" [(ngModel)]="currentAddLanguageString" [ngModelOptions]="{ standalone: true }" />
                  <app-svgs type="plus" color="secondary" (click)="addWeitereSprache()"></app-svgs>
                </div>
              </div>
            </app-collapsable>

            <!-- Erfahrung -->
            <app-collapsable [dontShowCollapsableHeader]="!isTopteamer"
              [valid]="!sedForm.controls['berufserfahrung'].invalid">
              <h4>Erfahrung</h4>
              <div *ngIf="isTopteamer">
                <p class="subscription">
                  Hast du in der Vergangenheit bereits Erfahrungen auf Messen,
                  Events etc. gesammelt?
                </p>
                <button (click)="infoModals.experiences = true" class="modal-button">
                  Infos zum Eintragen von Erfahrungen findest du hier
                </button>
              </div>
              <div>
                <h5 *ngIf="!isTopteamer">Berufserfahrung</h5>
                <div class="berufserfahrung" *ngIf="sedForm.get('berufserfahrung')?.value">
                  <div *ngFor="
                      let be of sedForm.get('berufserfahrung')?.value;
                      let i = index
                    " class="erfahrungen-card">
                    <div class="left">
                      <p>{{ be.veranstaltung }}</p>
                      <p class="p-light">{{ be.taetigkeit }}</p>
                      <p class="p-light">{{ be.markenname }}</p>
                    </div>
                    <app-svgs type="trash" color="secondary" (click)="removeBerufserfahrung(i)">
                    </app-svgs>
                  </div>
                  <div>
                    <app-button color="secondary" iconRight="plus" background="white"
                      (click)="openAddArbeitserfahrung()">
                      Hinzufügen
                    </app-button>
                  </div>
                </div>
                <div *ngIf="
                    sedForm.get('berufserfahrung')?.invalid && submitAttempt
                  " class="alert">
                  Bitte gib deine Berufserfahrungen an.
                </div>
              </div>
            </app-collapsable>

            <!-- Weitere Erfahrungen -->
            <app-collapsable *ngIf="isTopteamer" [valid]="!sedForm.controls['weitere_erfahrungen'].invalid">
              <h4>Weitere Erfahrungen</h4>
              <div>
                <p class="subscription">
                  z.B. Gastronomie, Auslandserfahrung etc.
                </p>
              </div>
              <div>
                <textarea formControlName="weitere_erfahrungen" cols="30" rows="10"></textarea>
              </div>
            </app-collapsable>
          </form>

          <form [formGroup]="agbForm" class="margin-top-30" *ngIf="!agbForm.disabled">
            <div *ngIf="!agbForm.controls.angaben_korrekt.disabled">
              <label for="angaben_korrekt" class="checkbox">
                <input type="checkbox" name="angaben_korrekt" id="angaben_korrekt" formControlName="angaben_korrekt" />
                Ich bestätige, dass alle gemachten Eingaben aktuell und korrekt
                sind.
              </label>
              <div *ngIf="agbForm.get('angaben_korrekt')?.invalid && submitAttempt" class="alert">
                Bitte bestätige, dass alle gemachten Eingaben aktuell und
                korrekt sind.
              </div>
            </div>
          </form>
        </app-data-collection-wrapper>

        <app-data-collection-wrapper title="3. Zusammenfassung" [collapsed]="currentlyOpenCard != 3" [ngClass]="{
            flexGrow: currentlyOpenCard == 3,
            noTopOverlap: currentlyOpenCard < 3
          }" (titleClicked)="ctaClicked(3)" [index]="3" [collapsedBg]="getCollapsedBg(3)"
          *ngIf="!isTopteamer && !isSedEditFormOnly && !isPersEditFormOnly">
          <form [formGroup]="agbForm">
            <table>
              <!-- Persönliche Daten -->
              <tr>
                <td colspan="2">
                  <h5 class="uppercase">Persönliche Daten</h5>
                </td>
              </tr>
              <tr *ngIf="persForm.get('geschlecht')?.value != 'd'">
                <td class="p-light">Ansprache</td>
                <td class="a">
                  {{
                  persForm.get("geschlecht")?.value == "m" ? "Herr" : "Frau"
                  }}
                </td>
              </tr>
              <tr>
                <td class="p-light">Name</td>
                <td class="a">
                  {{ persForm.get("vorname")?.value }}
                  {{ persForm.get("nachname")?.value }}
                </td>
              </tr>
              <tr>
                <td class="p-light">PLZ, Ort</td>
                <td class="a">
                  {{ persForm.get("plz")?.value }}
                  {{ persForm.get("ort")?.value }}
                </td>
              </tr>
              <tr>
                <td class="p-light">E-Mail</td>
                <td class="a">{{ persForm.get("email")?.value }}</td>
              </tr>
              <tr>
                <td class="p-light">Telefon</td>
                <td class="a">{{ persForm.get("tel")?.value }}</td>
              </tr>
              <!-- Status -->
              <tr>
                <td colspan="2">
                  <h5 class="padding-top-36 uppercase">Status</h5>
                </td>
              </tr>
              <tr *ngIf="beschaeftigungFormatted.length">
                <td class="p-light">Beschäftigung</td>
                <td class="a">
                  <div class="p-light" *ngFor="let besch of beschaeftigungFormatted" [innerHtml]="besch"></div>
                </td>
              </tr>
              <tr *ngIf="persForm.controls['existiert_hauptarbeitgeber'].enabled">
                <td class="p-light">Hauptarbeitgeber</td>
                <td class="a">
                  {{
                  persForm.get("existiert_hauptarbeitgeber")?.value
                  ? "Ja"
                  : "Nein"
                  }}
                </td>
              </tr>
              <!-- SED Card -->
              <tr>
                <td colspan="2">
                  <h5 class="padding-top-36 uppercase">SedCard</h5>
                </td>
              </tr>
              <tr>
                <td class="p-light">Haarfarbe</td>
                <td class="a">{{ sedForm.get("haarfarbe")?.value }}</td>
              </tr>
              <tr>
                <td class="p-light">Körpergröße</td>
                <td class="a">{{ sedForm.get("koerpergroesse")?.value }}</td>
              </tr>
              <tr>
                <td class="p-light">Shirtgröße</td>
                <td class="a">
                  {{ shirtArr[sedForm.get("shirtgroesse")?.value] }}
                </td>
              </tr>
              <tr
                *ngIf="
                  sedForm.get('fotos')?.value &&
                  sedForm.get('fotos')?.value.length
                ">
                <td class="p-light">Fotos</td>
                <td class="a">
                  <app-fileupload [uploadFiles]="sedForm.value['fotos']" [onlyPreview]="true">
                  </app-fileupload>
                </td>
              </tr>
              <tr *ngIf="
                  sedForm.get('berufserfahrung')?.value &&
                  sedForm.get('berufserfahrung')?.value.length
                ">
                <td colspan="2">
                  <h5 class="padding-top-36">Berufserfahrung</h5>
                </td>
              </tr>
              <span *ngIf="
                  sedForm.get('berufserfahrung')?.value &&
                  sedForm.get('berufserfahrung')?.value.length
                ">
                <tr *ngFor="let item of sedForm.get('berufserfahrung')?.value">
                  <td class="p-light">{{ item.veranstaltung }}</td>
                  <td class="a">{{ item.taetigkeit }} {{ item.markenname }}</td>
                </tr>
              </span>

              <tr>
                <td class="p-light" colspan="2">
                  <label for="agb" class="checkbox">
                    <input type="checkbox" name="agb" id="agb" formControlName="agb" />
                    {{ agbText }}
                  </label>
                  <div *ngIf="agbForm.get('agb')?.invalid && submitAttempt" class="alert">
                    Bitte akzeptiere die Allgemeinen Geschäftsbedingungen.
                  </div>
                </td>
              </tr>

              <tr>
                <td class="p-light" colspan="2">
                  <label for="newsletter" class="checkbox">
                    <input type="checkbox" name="newsletter" id="newsletter" formControlName="newsletter" />
                    {{ newsletterText }}
                  </label>
                </td>
              </tr>
            </table>
          </form>
        </app-data-collection-wrapper>
        <app-button *ngIf="btnTxt != ''" background="white" class="sticky-button" (click)="ctaClicked()"
          [disabled]="stickyBtnDisabled">
          {{ btnTxt }}
        </app-button>
      </div>
    </div>
  </div>
</div>