/* eslint-disable no-await-in-loop */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Media } from "@ttc_types/types";
import { Notify } from "notiflix";
import { AuthService } from "src/app/shared/auth.service";
import { environment } from "src/environments/environment.prod";
import { DataService } from "../../data.service";

@Component({
  selector: "app-fileupload",
  templateUrl: "./fileupload.component.html",
  styleUrls: ["./fileupload.component.scss"],
})
export class FileuploadComponent implements OnInit {
  fileUploadId: string = (Math.random() * 1000).toFixed(0);

  resourceUrl: string = `${environment.resourceUrl}/`;

  constructor(
    private data: DataService,
    private auth: AuthService,
    private sanitizer: DomSanitizer
  ) {
    setTimeout(() => {
      console.log(`uploadFiles`, this.uploadFiles);
    }, 2000);
  }

  @Input()
  uploadFiles: Media[] = [];

  @Input()
  @HostBinding("class.onlyPreview")
  onlyPreview: boolean = false;

  @Input()
  listView: boolean = false;

  @Input()
  allowPdf: boolean = false;

  @Input()
  allowVideo: boolean = false;

  @Input()
  hideAddButtonAfterLimit: boolean = false;

  acceptTypes: string = "image/*";

  @Input()
  maxFiles?: number = undefined;

  @Output()
  uploadFilesChange = new EventEmitter<Media[]>();

  @Output()
  isUploading = new EventEmitter<boolean>(false);

  public showDeleted: boolean = false;

  get uploadFilesFiltered() {
    return (this.uploadFiles || []).filter(
      (file) => !file.deleted || this.showDeleted
    );
  }

  ngOnInit(): void {
    console.log(`this.uploadFiles`, this.uploadFiles);
    if (this.allowPdf) {
      this.acceptTypes += ",application/pdf";
    }
    if (this.allowVideo) {
      this.acceptTypes += ",video/*";
    }
    this.showDeleted = ["pm", "superadmin"].includes(
      this.auth.currentUser?.role || ""
    );
  }

  addMediaClicked() {
    console.log("addMediaClicked");
    if (this.maxFiles) {
      const remaining =
        this.maxFiles -
        (this.uploadFiles || []).filter((el) => !el.deleted).length;
      if (remaining < 1) {
        Notify.info(
          `Es können leider keine weiteren Dateien hochgeladen werden.`
        );
        return;
      }
    }
    document?.getElementById(`hidden_file_input${this.fileUploadId}`)?.click();
  }

  deleteImage(uploadFilesIndex: number) {
    if (this.uploadFiles[uploadFilesIndex].isUploading) return;
    this.uploadFiles[uploadFilesIndex].deleted = true;
    this.sortUploadFilesArray();
  }

  sortUploadFilesArray() {
    this.uploadFiles.sort((a, b) => {
      if (a.deleted) return 1;
      if (b.deleted) return -1;
      return 0;
    });
  }

  async onFileChange($event: any) {
    this.isUploading.emit(true);
    const filesLength = $event.target.files?.length || 0;
    if (filesLength) {
      const remaining =
        (this.maxFiles || 999) -
        (this.uploadFiles || []).filter((el) => !el.deleted).length;

      if (remaining < 1) {
        Notify.info(
          `Es können leider keine weiteren Dateien hochgeladen werden. Bitte lösche erst welche.`
        );
        return;
      }

      if (filesLength > remaining) {
        Notify.info(`Es werden nur ${remaining} Dateien hochgeladen.`);
      }

      const thumbnails = await this.getThumbnails(
        $event,
        Math.min(filesLength, remaining)
      );

      for (let i = 0; i < Math.min(filesLength, remaining); i++) {
        // check for mime type
        console.log(`$event.target.files[i].type`, $event.target.files[i].type);
        if (
          !$event.target.files[i].type.includes("image/") &&
          !(this.allowPdf && $event.target.files[i].type.includes("/pdf")) &&
          !(this.allowVideo && $event.target.files[i].type.includes("video/"))
        ) {
          Notify.info(
            `Es sind nur Bilder${this.allowPdf ? ", PDFs" : ""}${
              this.allowVideo ? ", Videos" : ""
            } erlaubt.`
          );
        } else {
          this.uploadFiles = this.uploadFiles || [];
          this.uploadFiles.push({
            filename: $event.target.files[i].name,
            thumbnail: thumbnails[i],
            mimeType: $event.target.files[i].type,
            fileBaseBlob: $event.target.files[i].type.includes("image/")
              ? await this.generateThumbnail($event.target.files[i], 2048)
              : $event.target.files[i],

            uploaded: false,
            isUploading: false,
          });
          this.sortUploadFilesArray();
        }
      }

      const uploadPromises: Promise<any>[] = [];
      console.log(`this.uploadFiles`, this.uploadFiles);
      const keys = Object.keys(this.uploadFiles);
      for (let i = 0; i < keys.length; i++) {
        if (
          !this.uploadFiles[i].uploaded &&
          !this.uploadFiles[i].isUploading &&
          !this.uploadFiles[i].id
        ) {
          console.log("came here");
          this.uploadFiles[i].isUploading = true;
          uploadPromises.push(
            new Promise((res) => {
              this.data.uploadFile(this.uploadFiles[i]).then((result) => {
                res({
                  ufId: i,
                  result,
                });
              });
            })
          );
        }
      }
      const uploadResults = await Promise.all(uploadPromises);
      const idsToSplice: string[] = [];
      uploadResults.forEach((ur) => {
        if (!ur?.result?.errors.length) {
          const updatedFile = this.uploadFiles[ur.ufId];

          updatedFile.nameOnDisk = ur.result.fileName;
          updatedFile.uploaded = true;
          updatedFile.url = this.resourceUrl + updatedFile.nameOnDisk;
          updatedFile.isUploading = false;

          delete updatedFile.thumbnail;

          console.log(`updatedFile`, updatedFile);
        } else {
          Notify.failure("Einer oder mehrere Uploads haben nicht geklappt");
          idsToSplice.push(ur.ufId);
        }
      });
      // eslint-disable-next-line no-param-reassign
      $event.target.value = "";
      console.log(`idsToSplice`, idsToSplice);
      idsToSplice.reverse().forEach((splice) => {
        this.uploadFiles.splice(Number.parseInt(splice, 10), 1);
      });
      console.log(`this.uploadFiles`, this.uploadFiles);
    }
    this.isUploading.emit(false);
    this.uploadFilesChange.emit(this.uploadFiles);
  }

  openNewTab(image: Media) {
    if (image.nameOnDisk) {
      window.open(this.resourceUrl + image.nameOnDisk, "_blank");
    }
  }

  async getThumbnails($event: any, onlyTrailing?: number) {
    const { files } = $event.target;
    const thumbnails: { [key: number]: unknown } = {};
    for (let i = 0; i < (onlyTrailing || files.length); i++) {
      try {
        if (files[i].type.includes("video/")) {
          thumbnails[i] = await this.getVideoCover(files[i]);
        } else if (files[i].type.includes("image/")) {
          thumbnails[i] = this.getPlaceholderImage();
        }
      } catch (error) {
        console.log("error getThumbnails", error);
      }
    }
    return thumbnails;
  }

  getVideoCover(file: any, seekTo = 0.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(new Error(`error when loading video file${JSON.stringify(ex)}`));
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(new Error("video is too short."));
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          resolve(ctx?.canvas.toDataURL("image/jpeg", 1.0));
        });
      });
    });
  }

  getPlaceholderImage() {
    return this.sanitizer.bypassSecurityTrustUrl(
      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
    );
  }

  async generateThumbnail(
    inputEl: File,
    maxHeightWidth: number
  ): Promise<Blob> {
    const bitmap = await createImageBitmap(inputEl, {
      resizeWidth: maxHeightWidth,
      resizeQuality: "pixelated",
    });

    // @ts-ignore - OffscreenCanvas is not in the current lib
    const canvas = new OffscreenCanvas(bitmap.width, bitmap.height);
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
    const data = await canvas.convertToBlob({ type: "image/jpeg" });

    return data;
  }

  toBase64(file: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() ?? "");
      reader.onerror = (error) => reject(error);
    });
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  get showUploadButton() {
    if (this.onlyPreview) {
      return false;
    }

    const limitReached =
      this.maxFiles && this.uploadFilesFiltered.length >= this.maxFiles;

    if (this.hideAddButtonAfterLimit && limitReached) {
      return false;
    }

    return true;
  }
}
