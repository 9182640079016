import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { Notify } from "notiflix";
import { Zeit } from "@ttc_types/types";
import { branchen } from "@ttc_types/datasets";
import { DataService } from "../../../../data.service";

@Component({
  selector: "app-infos",
  templateUrl: "./infos.component.html",
  styleUrls: ["./infos.component.scss"],
})
export class InfosComponent implements OnInit {
  public branchen = branchen;

  zeiten: Zeit[] = [
    {
      zeit_von: "",
      zeit_bis: "",
    },
  ];

  public zeitraum_von_bis = {
    startDate: dayjs(),
    endDate: dayjs(),
  };

  submitAttempt = false;

  constructor(
    private fb: FormBuilder,
    public data: DataService,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    // console.log(`router`, router);
  }

  honorarOderAufAnfrageValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // console.log(`honorarOderAufAnfrageValidator control`, control.value);
    let honorar = control.value.honorar_eur;
    honorar = honorar != "" && honorar;
    let { honorar_auf_anfrage } = control.value;
    honorar_auf_anfrage = honorar_auf_anfrage != "" && honorar_auf_anfrage;

    if (honorar || honorar_auf_anfrage) {
      return null;
    }
    return { invalid: true };
  };

  minLengthArray: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // console.log(`minLengthArray control`, control.value);

    const valid =
      control.value?.length &&
      control.value?.[0]?.zeit_von?.length &&
      control.value?.[0].zeit_bis?.length;

    if (valid) {
      return null;
    }
    return { invalid: true };
  };

  infoForm: FormGroup = new FormGroup({
    aktionsinhalt: new FormControl("", [Validators.required]),
    anforderungsprofil: new FormControl("", [Validators.required]),
    branche: new FormControl(""),
    zeitraum_von: new FormControl("", [Validators.required]),
    zeitraum_bis: new FormControl("", [Validators.required]),
    zeiten: new FormControl(
      this.fb.array([new FormControl({ zeit_von: "", zeit_bis: "" })]),
      { validators: this.minLengthArray }
    ),
    honorar: this.fb.group(
      {
        honorar_eur: new FormControl(""),
        honorar_auf_anfrage: new FormControl(false),
      },
      { validators: this.honorarOderAufAnfrageValidator }
    ),
    aktions_standorte: new FormControl("", [Validators.required]),
  });

  removeZeit(i: number) {
    if (this.zeiten.length > 1) {
      this.zeiten.splice(i, 1);
    }
    this.updateForm();
  }

  addZeit(): void {
    this.zeiten.push({ zeit_von: "", zeit_bis: "" });
  }

  eventBusSubject: Subscription | null = null;

  ngOnInit(): void {
    this.eventBusSubject = this.data.eventBus.subscribe((event) => {
      console.log(`eventBusSubject event`, event);
      if (event == "saveClicked") {
        this.submitForm();
      } else if (event == "publishClicked") {
        this.submitForm(true);
      } else if (event == "hideClicked") {
        this.submitForm(false);
      } else if (event == "updateInfoForm") {
        console.log(`event`, event);
        if (typeof this.data.currentProject == "object") {
          for (const [key, value] of Object.entries(this.data.currentProject)) {
            if (
              ![
                "id",
                "zeiten",
                "zeitraum_von",
                "zeitraum_bis",
                "created_by_id",
              ].includes(key)
            ) {
              const control = this.infoForm.get(key);
              control?.patchValue(value);
            }
          }
          this.zeiten = this.data.currentProject.zeiten;
          this.zeitraum_von_bis = {
            startDate: dayjs(this.data.currentProject.zeitraum_von),
            endDate: dayjs(this.data.currentProject.zeitraum_bis),
          };
        }
        this.updateForm();
      }
    });
    this.updateForm(true);
  }

  ngOnDestroy() {
    if (this.eventBusSubject) {
      this.eventBusSubject?.unsubscribe();
    }
  }

  updateForm(onInit: boolean = false) {
    console.log(`this.zeiten`, this.zeiten);
    console.log(`this.data.currentProject`, this.data.currentProject);
    if (onInit && this.data.currentProject) {
      if (this.data.currentProject.zeiten) {
        if (Array.isArray(this.data.currentProject.zeiten)) {
          this.zeiten = this.data.currentProject.zeiten;
        } else {
          this.zeiten = [this.data.currentProject.zeiten];
        }
      }
      console.log(`this.zeiten`, this.zeiten);
      // this.
      if (this.data.currentProject.zeitraum_von) {
        this.zeitraum_von_bis.startDate = dayjs(
          this.data.currentProject.zeitraum_von
        );
      }
      if (this.data.currentProject.zeitraum_bis) {
        this.zeitraum_von_bis.endDate = dayjs(
          this.data.currentProject.zeitraum_bis
        );
      }
      this.infoForm.patchValue(this.data.currentProject);
    }
    (this.infoForm.get("zeiten") as FormControl).patchValue(this.zeiten);
    this.infoForm.updateValueAndValidity();

    this.infoForm
      .get("zeitraum_von")
      ?.patchValue(this.zeitraum_von_bis.startDate.format());

    this.infoForm
      .get("zeitraum_bis")
      ?.patchValue(this.zeitraum_von_bis.endDate.format());
    console.log(`this.infoForm.value`, this.infoForm.value);
  }

  async submitForm(publish: boolean | undefined = undefined) {
    this.submitAttempt = true;
    this.data.currentProjectTitleSubmitAttempt = true;
    console.log(`this.infoForm.invalid`, this.infoForm);
    console.log(
      `this.data.currentProjectTitleSubmitAttempt`,
      this.data.currentProjectTitleSubmitAttempt
    );
    if (this.infoForm.invalid) {
      Notify.failure("Es sind noch nicht alle Angaben.");
      return false;
    }
    if (
      !this.data.currentProjectTitle ||
      !this.data.currentProjectTitle.length
    ) {
      Notify.failure("Bitte gib einen Titel an.");
      return false;
    }
    if (typeof publish !== "undefined") {
      this.data.currentProjectPublished = publish;
    }

    this.data.currentProject = Object.assign(
      this.data.currentProject || {},
      Object.assign(this.infoForm.value, {
        titel: this.data.currentProjectTitle,
        internal_title: this.data.currentProjectInternalTitle,
        published: this.data.currentProjectPublished,
        created_by_id: this.data.auth.currentUser?.id,
      })
    );

    this.data.currentProject = await this.data.uploadCurrentProject();
    Notify.success("Das Projekt wurde erfolgreich aktualisiert.");
    console.log(
      `this.data.currentProject after upload`,
      this.data.currentProject
    );

    if (this.router.url.split("/").includes("add")) {
      this.router.navigateByUrl(
        `meine-projekte/edit/${this.data.currentProject?.id}`
      );
    }

    return true;
  }
}
