/* eslint-disable no-use-before-define */

/* eslint-disable prettier/prettier */
export type SvgsTypes =
  | "search"
  | "suitcase"
  | "avatar"
  | "login"
  | "logout"
  | "chevron-left"
  | "chevron-down"
  | "chevron-down-small"
  | "chevron-right"
  | "settings"
  | "infos"
  | "tasks"
  | "user"
  | "add-user"
  | "clock"
  | "remove"
  | "eye"
  | "eye-crossed"
  | "calendar16x16"
  | "location16x16"
  | "eur16x16"
  | "checkmark-circle"
  | "check"
  | "telephone"
  | "smartphone"
  | "email"
  | "close"
  | "minus"
  | "plus"
  | "trash"
  | "page"
  | "page-2"
  | "page-search"
  | "yoga"
  | "coin"
  | "undo-circle"
  | "filter"
  | "page-edit"
  | "calendar"
  | "checkmark"
  | "refresh"
  | "timer"
  | "warning-circle-outlined"
  | "star-outline"
  | "edit-pencil"
  | "save-floppy-disk"
  | "table-2-columns"
  | "link"
  | "remove-link"
  | "verified-user"
  | "remove-user"
  | "enlarge-round-arrow"
  | "enlarge-round-arrow-small"
  | "add-circled-outline"
  // | "internet"
  | "cloud-download";

export type Toggle = {
  label: string | number;
  value?: string | number;
  icon?: SvgsTypes;
  routerLink?: string;
  disabled?: boolean;
  iconSizePxWidth?: number;
  iconSizePxHeight?: number;
};

export interface Media {
  id?: number;

  mimeType?: string;
  thumbnail?: string | unknown;

  filename?: string;
  fileBaseBlob?: string;

  uploaded?: boolean;
  isUploading?: boolean;

  nameOnDisk?: string;

  url?: string;

  deleted?: boolean;
}

export const Geschlecht = {
  m: "männlich",
  w: "weiblich",
  d: "divers",
};

export interface Profile {
  user_id?: number;

  // zur person
  geschlecht: "m" | "w" | "d";
  vorname: string;
  weitere_vornamen?: string | null;
  nachname: string;
  email: string;
  tel: string;

  // bestehend
  geburtsname?: string;
  geburtsdatum?: string;
  geburtsstadt?: string;
  geburtsland?: string;
  // deprecated
  staatsangehoerigkeit?: string;

  staatsangehoerigkeit_arr?: string[];
  ausweisnummer?: string;
  ausweis_vorne?: Media[];
  ausweis_hinten?: Media[];

  // kontakt
  // bestehend
  strasse?: string;
  hausnr?: string;
  plz?: string;
  ort?: string;
  addresszusatz?: string;
  wohnland?: string;
  // email von oben
  // tel von oben

  // abschluesse
  // bestehend
  hoechster_allgemeiner_schulabschluss?: string;
  hoechster_beruflicher_abschluss?: string;

  // status bei arbeitsbeginn
  arbeitsbeginn_status: { [key: string]: any };
  arbeitsbeginn_status_additional: { [key: string]: any };
  existiert_hauptarbeitgeber: boolean;

  aufenthaltstitel: boolean;
  aufenthaltstitel_nachweis?: Media[];
  gewerbeschein: boolean;
  gewerbeschein_nachweis: Media[];
  gesundheitszeugnis: boolean;
  gesundheitszeugnis_nachweis: Media[];

  // laufendes jahr jobs
  // bestehend
  laufendes_jahr: { [key: string]: BeschaeftigungLaufendesJahr[] };

  // Rechtliches
  // bestehend
  steueridentifikationsnummer?: string;
  steuerklasse?: number;
  sozialversicherungsnummer?: string;
  krankenkasse_typ?: "gesetzlich" | "privat" | "andere";
  krankenkasse_name?: string;
  familienstand?: "ledig" | "verheiratet";
  religionszugehoerigkeit?: { id: string; label: string };
  kinderfreibetrag?: boolean;

  note?: string;

  // bankverbindung
  // bestehend
  iban?: string;
  bic?: string;
}

export interface ArbeitsbeginnStatusProfileView {
  name: string;
  fach?: string;
  nachweise: Media[];
  gueltig_bis?: string | null;
}

export interface Berufserfahrung {
  veranstaltung?: string;
  taetigkeit?: string;
  markenname?: string;
}

export interface VorhandeneMobilitaet {
  PKW: boolean;
  Bahncard: boolean;
}

export interface Sprache {
  label: string;
  level: string;
}
export interface Sprachen {
  deutsch: string;
  englisch: string;
  spanisch: string;
  franzoesisch: string;
  italienisch: string;
  weitere: Sprache[];
}

export interface SED {
  haarfarbe: string;
  koerpergroesse: number;
  gewicht?: number;
  shirtgroesse: number;

  jacke_sacko?: number;
  hose_rock?: number;
  jeansweite?: number;
  jeanslaenge?: number;
  schuhgroesse?: number;

  guertellaenge?: number;
  kragenweite?: number;

  fuehrerschein?: boolean;
  vorhandene_mobilitaet?: VorhandeneMobilitaet;

  sprachen: Sprachen;

  fotos?: Media[];
  berufserfahrung?: Berufserfahrung[];
  weitere_erfahrungen?: string;
}

export type BeschaeftigungLaufendesJahr =
  | {
      formControlName: string;
      arbeitgeber: string;
      label:
        | "Minijob"
        | "Werkstudent"
        | "KurzfristigeBeschaeftigung"
        | "Kurzfristige Beschäftigung";
      tage_beschaeftigt?: number;
      zeitraum_bis?: string | Date;
      zeitraum_von?: string | Date;
      checked?: boolean;
    }
  | {
      formControlName: string;
      arbeitgeber: string;
      label: "Praktikum";
      praktikum_art: "Pflicht" | "Freiwillig";
      tage_beschaeftigt?: number;
      zeitraum_bis?: string | Date;
      zeitraum_von?: string | Date;
      checked?: boolean;
    };

export type Zeit = {
  zeit_von: string;
  zeit_bis: string;
};

export type Honorar = {
  honorar_auf_anfrage: boolean;
  honorar_eur: number | undefined;
};

export type Projekt =
  | {
      id: number | undefined;
      titel: string;
      internal_title: string;
      // infos
      aktionsinhalt: string;
      anforderungsprofil: string;
      branche: string;
      zeitraum_von: Date;
      zeitraum_bis: Date;
      zeiten: Zeit[];
      honorar: Honorar;
      aktions_standorte: string;

      applications?: Application[];

      // neu 2022-08-11 projekteistellungen start
      personalbedarf?: number;
      zusaetzliche_vertragsklauseln?: string;
      abrechnung_kostenstelle?: string;
      abrechnung_topteam_taetigkeit?: string;
      // neu 2022-08-11 projekteistellungen end

      project_uuid?: string;
      kunden_email?: string;
      mitteilung?: string;
      auswahl_durch_kunden_zulassen?: boolean;
      link_already_sent_once?: boolean;
      link_already_sent_once_date?: Date | null;
      checkliste?: any[];
      // ablauf
      // personal
      // Zeiterfassung
      published: boolean;
      created_by_id?: number;
      created_at?: Date;
      updated_at?: Date;

      kunde_ansprechpartner_name?: string;
      kunde_ansprechpartner_email?: string;
      kunde_ansprechpartner_tel?: string;

      ansprechperson?: Application;
      meta?: {
        action_required?: number;
        bewerbungen_gesamt?: number;
        bewerbungen_neu?: number;
        in_auswahl?: number;
        zusagen?: number;
      };
      job_roles?: string[] | null;
      bewerbung_moeglich?: boolean;

      jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen?: boolean;

      deleted?: boolean;
      archived?: boolean;

      live_checkin?: boolean;
    }
  | undefined;

export type ApplicationRoles =
  | "arbeitskraft" // normal user
  | "ansprechperson" // leading project manager
  | "weiterer_projektmanager" // additional project manager
  | "teamleiter_vor_ort" // leading teamlead
  | "additional_teamleads"; // additional teamleads

export type ApplicationAction =
  | "ablehnen"
  | "als_topteamer_aufnehmen" // und absagen
  | "daten_anfordern"
  //
  | "daten_ok"
  | "daten_fehlerhaft"
  | "unentschlossen"
  //
  | "daten_ok_und_in_auswahl"
  | "aus_auswahl_entfernen"
  | "in_auswahl_fuer_auftraggeber"
  | "vertrag_erstellen"
  | "vertrag_erneut_erstellen"
  | "vertrag_senden"
  | "vertrag_erneut_senden"
  | "kuendigen"
  | "sed_card_bearbeiten"
  | "sed_card_speichern"
  | "kuendigung_ueberspringen"
  | "kuendigung_abschicken";

export type RegistrationAction = "gesehen" | ApplicationAction;

export const applicationStatusArray = [
  "neu",
  "warten_auf_daten",
  "daten_pruefen",
  //
  "projekt_pool", // unentschlossen -> daten müssen noch nicht oki doki sein
  //
  "auswahl_auftraggeber_invisible", // in auswahl für auftraggeber -> sichtbar in link
  "auswahl_auftraggeber_visible", // in auswahl für auftraggeber -> unsichtbar in link
  //
  "auftraggeber_akzeptiert",
  "auftraggeber_abgesagt",
  "warten_auf_vertrag", // arbeitskraft muss unterzeichnen
  "vertrag_gegenzeichnen", // admin/ pm muss gegenzeichnen
  "erledigt_verbindlich", // final - alle haben unterzeichnet;
  // was bei kündigung machen?
  "vertrag_geloescht",
  "abgesagt",
  "gekuendigt",
] as const;

export const registrationStatusArray = [
  // until now only neu, gesehen and erledigt are used
  // neu has a gold point in arbeitskräfte and changes to gesehen after viewed
  // status goes to erledigt as soon as the first application is approved
  "neu",
  "gesehen",
  "warten_auf_daten",
  "daten_pruefen",
  "erledigt",
  undefined,
  null,
] as const;

export type RegistrationStatus = (typeof registrationStatusArray)[number];
// eslint-disable-next-line no-shadow

export type ApplicationStatus = (typeof applicationStatusArray)[number];

export const ApplicationStatusStringArbeitskraft: {
  [K in ApplicationStatus]: { label: string; icon: SvgsTypes; iconBg: string };
} = {
  neu: {
    label: "Bewerbung wird geprüft",
    icon: "page-search",
    iconBg: "secondary",
  },
  warten_auf_daten: {
    label: "Daten erforderlich",
    icon: "page-search",
    iconBg: "secondary",
  },
  daten_pruefen: {
    label: "Bewerbung wird geprüft",
    icon: "page-search",
    iconBg: "secondary",
  },
  projekt_pool: {
    label: "Bewerbung wird geprüft",
    icon: "page-search",
    iconBg: "secondary",
  },
  auswahl_auftraggeber_invisible: {
    label: "Bewerbung wird geprüft",
    icon: "page-search",
    iconBg: "secondary",
  },
  auswahl_auftraggeber_visible: {
    label: "Bewerbung wird geprüft",
    icon: "page-search",
    iconBg: "secondary",
  },
  auftraggeber_akzeptiert: {
    label: "Bewerbung wird geprüft",
    icon: "page-search",
    iconBg: "success",
  },
  auftraggeber_abgesagt: {
    label: "Job nicht erhalten",
    icon: "close",
    iconBg: "fail",
  },
  warten_auf_vertrag: {
    label: "Vertrag bereit",
    icon: "page-search",
    iconBg: "secondary",
  },
  vertrag_gegenzeichnen: {
    label: "Vertrag wird gegengezeichnet",
    icon: "page-search",
    iconBg: "secondary",
  },
  erledigt_verbindlich: {
    label: "Job erhalten",
    icon: "check",
    iconBg: "success",
  },
  vertrag_geloescht: {
    label: "Vertrag gelöscht",
    icon: "close",
    iconBg: "fail",
  },
  abgesagt: {
    label: "Job nicht erhalten",
    icon: "close",
    iconBg: "fail",
  },
  gekuendigt: {
    label: "Job gekündigt",
    icon: "close",
    iconBg: "fail",
  },
};

export type Zulagen = {
  Fahrkosten_eur: number;
  Kilometergeld_eur_km: number;
  Kilometergeld_km: number;
  Mitnahme_anderer_im_Fahrzeug_eur: number;
  Bonus_eur: number;
  Freunde_Bonus_eur: number;
  Durchhaltebonus_eur: number;
  Auslage_fuer_description: string;
  Auslage_fuer_eur: number;

  Aufschlag_h_eur: number;
  Tagessatz_eur: number;
};

export type ZulagenNeu = {
  // neu
  Briefing_eur: number; // 30
  Nachtzuschlag_eur: number; // 503
  Vorschuss_eur: number; // 9079
  Spesen_eur: number; // 9979

  // alt
  Bonus_eur: number; // 32
  Auslage_fuer_description: string;
  Auslage_fuer_eur: number; // 9029
  Freunde_Bonus_eur: number; // 300
  Durchhaltebonus_eur: number; // 31
  Kilometergeld_eur: number; // 9978
  Fahrkosten_eur: number; // 11

  Aufschlag_h_eur: number;
  Tagessatz_eur: number;

  // fällt raus
  // Kilometergeld_eur_km: number;
  // Kilometergeld_km: number;
  // Mitnahme_anderer_im_Fahrzeug_eur: number;
};

export type Application = {
  id: number | undefined;
  role?: ApplicationRoles;
  status?: ApplicationStatus;
  sed_card?: SED;
  project?: Projekt;
  user_id?: number;
  project_id?: number;
  created_at?: Date;
  updated_at?: Date;
  is_admin?: boolean;
  timeslots?: TimetrackerSlot[];
  user?: User;
  all_data_approved_by_pm_ts?: Date | null;
  zulagen?: (ZulagenNeu & Zulagen) | null;

  job_role: string;

  sed_card_confirmed_by_user_serialized?: SED;
  profile_confirmed_by_user_serialized?: Profile;
  sed_card_confirmed_by_user_serialized_ts?: Date;
  profile_confirmed_by_user_serialized_ts?: Date;

  // timestampstring
  in_auswahl_seit?: string;

  meta?: {
    exist_times_after?: string;
  };
};

export type Document = {
  id?: number;
  contact_email: string;
  base64_signature: string;
  timestamp: number;
  project_id: number;
};

export type UserRole = "regular" | "pm" | "superadmin";

export interface Review {
  id?: number;
  review_text?: string;
  reviewer?: User;
  last_editor?: User;
  project?: Projekt;
  // project_name?: string;
  // reviewer_name?: string;
  review_date?: Date;
  last_edit_by_id?: number;
  last_edit_date?: Date;
}

export interface User {
  id?: number;
  personalnummer?: number;
  email: string;
  password?: string;

  // firstname?:string,
  // lastname?:string,
  role: UserRole;

  is_topteamer?: boolean;

  remember_me_token?: string;
  created_at?: string;
  updated_at?: string;

  main_sed_card?: SED;
  profile?: Profile;
  applications?: Application[];

  registration_status?: RegistrationStatus;

  // amount of all applications to see if only register
  // 0 applications -> only register
  application_count?: number;
  meta?: {
    application_count?: number;
    has_applications?: string;
    letzter_einsatz?: Date;
  };

  newsletter?: boolean;
  agb?: boolean;
  last_login?: Date | undefined | null;
  last_activity?: Date | undefined | null;

  erfahrungen_count?: number;

  personal_category?: string;
  reviews?: Review[];

  deleted: boolean;
  activated: boolean;
  imported?: boolean;
  // profile_sed_approved_at_least_once?: boolean;

  // auswahl vars
  in_auswahl_seit?: number;
  has_golden_dot?: boolean;

  selected?: boolean;
}

export type AllUsersResult = {
  data: User[];
  meta: { current_page: number; per_page: number; total: number };
};

export type SelectableDay = {
  time: number;
  weekdayShort: string;
  dateShort: string;
  selected: boolean;
  selectable: boolean;
};

export type TimetrackerSlot = {
  id?: number;
  title?: string;
  vorname?: string;
  nachname?: string;
  application_id?: number;
  time_from: number;
  time_to?: number;
  time_break?: number;
  fake_time_from?: number | null;
  fake_time_to?: number | null;
  fake_time_break?: number | null;
  document_id?: number;
  formattedDuration?: string;
  formattedBreak?: string;
  is_already_established_in_datev_export?: boolean;
  live_checkin?: boolean;
  edited_by_admin?: boolean;
};

export type TeamTimeSlotDay = {
  weekday: string;
  dateShort: string;
  dateLong: string;
  startTimestamp: number;
  endTimestamp: number;
  overallTime: number;
  overallTimeFormatted?: string;
  document_ids: number[];
  collapsed?: boolean;
  timeSlots?: TimetrackerSlot[];
  openCheckouts: number;
  completeTimeslots: number;
};

export type ApplicationPermission = {
  user_id: number;
  project_id: number;
  role: ApplicationRoles;
  is_admin: boolean | null;
};

export interface ShowArbeitskraefte extends User {
  letzter_einsatz?: string;
  alter?: string;
  // erfahrungen_count?: number;
}

export type TtcColors =
  | "primary"
  | "primary120"
  | "primary90"
  | "primary80"
  | "primary60"
  | "primary40"
  | "primary20"
  | "primary10"
  | "white"
  | "secondary"
  | "success"
  | "fail"
  | "overlay_bg";
// export interface showPersonal extends User {
//   application_status: string;
//   erfahrungen_count?: number;
//   wohnort: string;
// }

export interface DokumentVorlage {
  id?: number;
  name: string;
  template: string;
}

export interface EmploymentContract {
  id?: number;
  final_contract_text?: string;
  time_employer_signature?: Date;
  time_employee_signature?: Date;
  employer_signature?: string;
  employee_signature?: string;
  application_id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  time_sign_until_date?: Date;

  dismissal_executor_user_id?: number | null;
  dismissal_executor_user?: User | null;
  dismissal_executor_name?: string | null;
  dismissal_date?: Date | null;
  final_dismissal_text?: string | null;

  contract_file_name?: string | null;
  dismissal_file_name?: string | null;
}

export interface Kontakt {
  secondaryBG?: boolean;
  primaryBG?: boolean;
  title: string;
  subtitle?: string;
  tel: string;
  email: string;
}
export interface ContractInterpolationVariables {
  var_arbeitnehmer_adresse?: string;
  var_zu_unterschreiben_bis?: string;
  var_projekt_zeitraum_von?: string;
  var_projekt_zeitraum_bis?: string;
  var_eur_stunde?: string;
  var_briefing?: string; // = standardmäßig 3h?
  var_projektleitung?: string;
  var_einsatzzeiten?: string;
  var_projekt_name?: string;
  var_zusaetzliche_vertragsklauseln?: string;
}

export const sortDirections = [undefined, "asc", "desc"];

export type sortDirectionKeys =
  | null
  | "vorname"
  | "email"
  | "role"
  | "letzter_einsatz"
  | "alter"
  | "plz"
  | "anzahl_erfahrungen_bei_topteam"
  | "letzte_aktivitaet"
  | "is_topteamer";

export type sortDirection = {
  field: sortDirectionKeys;
  order: number;
};

export const transactionModelsArr = [
  "Application",
  "Time",
  // "EmploymentContract",
  "SedCard",
  "Profile",
  "User",
] as const;

export const protokollModelTranslations = {
  Application: "Bewerbung",
  Time: "Zeiterfassung",
  SedCard: "Sedcard",
  Profile: "Daten",
  User: "User",
};

// eslint-disable-next-line no-shadow

export type transactionModels = (typeof transactionModelsArr)[number];

export interface TransactionLogType {
  id?: number;

  model: transactionModels;
  model_id: number;

  field: string;

  value_from?: any;
  value_to?: any;

  user_id?: number | null;
  user?: User | any;

  actor_id?: number;
  actor: User | any;

  project_id?: number | null;
  project?: Projekt | any;

  bulk_id?: string | null;

  time_day?: Date | null;

  datev_exported_at?: Date | null;

  snapshot?: any | null;

  createdAt?: Date;
  updatedAt?: Date;
  created_at?: Date;
}

export type JobRole = {
  name: string;
  applications: Application[];
};

export type DatevExport = {
  id: number;
  iteration: number;
  wiederanmeldungen: string;
  neuanmeldungen: string;
  festgeschrieben: Date;
  is_temporary_export: boolean;
  user_id_eintritts_austrittsdatum_object: {
    [key: number]: {
      EintrittdatumAustrittdatum: string;
    };
  };
  month: Date;
  created_at: Date;
  updated_at: Date;
};

export type DatevExportResponse = {
  errors: string[];
  warnings: string[];
  success: null | string;
};

export type CheckInQrCodeResult = {
  ttConnectQrCheckinQrCode: {
    projectId: number;
    userId: number;
  };
};
